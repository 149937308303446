<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page_header_new";
import appConfig from "@/app.config";
import {mapState} from "vuex";
import {loggedInfoObject} from "@/state/helpers";
import axios from "axios";
import {msgConfirmOptCenter, msgModalOptCenter} from "@/components/globalFunctions";

export default {
  page: {
    title: "푸시알람전송",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },

  data() {
    return {
      deviceId: '9876543210',
      title: "푸시알람전송",
      pushCNT: "무제한",
      marketName: "크리톡",
      pushmsg: "",
      previewImg: "",
      pushImg: "",
      noImg: "https://cdn1.critalk.com/static/images/no_image.png",
      openEx: false,
      openCNTguide: false,
    };
  },

  components: {
    Layout,
    PageHeader,
  },

  computed: {
    ...mapState(loggedInfoObject)
  },

  mounted() {
    //초기화면 설정
    this.initPage()
    this.callRemainPush()
  },

  methods: {
    //초기화면 설정
    initPage() {
      this.marketName = this.logged_info.mart_name
      this.pushImg = ""
      this.pushmsg = ""
      this.previewImg = ""
      this.openEx = false
    },
    //파일선택시 이미지 미리보기 처리 및 이미지파일을 변수에 넣어두기
    previewImage() {
      var input = event.target;
      if (input.files && input.files[0]) {
        var reader = new FileReader()
        reader.onload = e => {
          //이미지 미리보기 변수에 이미지 넣기
          this.previewImg = e.target.result
          //이미지 파일을 변수에 넣어두기
          this.pushImg = input.files[0]
          console.log(this.pushImg)
        };
        reader.readAsDataURL(input.files[0]);
      } else {
        this.pushImg = null;
      }
    },
    //푸시 발송
    submitPush() {
      this.$bvModal.msgBoxConfirm("푸시메시지는 발송시 취소 및 수정이 되지 않습니다.\n바로 발송하시겠습니까?", msgConfirmOptCenter)
          .then((sel) => {
            if (sel) {
              console.log(this.pushImg)
              const fd = new FormData()
              fd.append("mart_srl", this.logged_info.mart_srl)
              fd.append("comment", this.pushmsg)
              fd.append("title", this.logged_info.mart_name)
              fd.append("deviceid", this.deviceId)
              fd.append("mart_name", this.logged_info.mart_name)
              fd.append("img", this.pushImg)
              axios.post(`/api/push`, fd)
                  .then((response) => {
                    console.log(response.data)
                    if (response.data.status === "success") {
                      this.$bvModal.msgBoxOk("푸시메시지가 성공적으로 발송되었습니다.", msgModalOptCenter)
                      this.initPage()
                      this.callRemainPush()
                    } else {
                      this.$bvModal.msgBoxOk("푸시메시지 발송에 실패하였습니다.\n다시 시도해주세요.", msgModalOptCenter)
                    }
                  })
                  .catch(error => {
                    console.log(error)
                  })
            }
          })
    },
    //최고 노이미지 이미지의 Url 설정
    urlEncoding(url) {
      let encodeUrl = encodeURI(url)
      return encodeUrl
    },
    //푸시메시지 예문 보여줄지 여부
    showEx() {
      this.openEx = !this.openEx
    },
    //푸시메시지 남은횟수에 대한 가이드를 보여줄지 여부
    showGuide() {
      this.openCNTguide = !this.openCNTguide
    },
    //남은 푸시메시지 횟수
    callRemainPush() {
      let url = `/api/push/${this.logged_info.mart_srl}`

      axios.get(url)
          .then((res) => {
            console.log(res.data)
            if (res.data.cnt == '-1') {
              this.pushCNT = '무제한'
            } else {
              this.pushCNT = res.data.cnt+'건'
            }
          })
          .catch( err => console.log(err))
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title"/>
    <div class="row">
      <div class="row">
        <div class="col-6">
          <!-- 기본정보 영역 -->
          <div class="card">
            <div class="card-body">
              <!--            <hr class="my-4" />-->
              <div>

                <div style="position: relative; padding-bottom: 14px">&nbsp;
                  <label class="text-muted mb-1" style="position: absolute; right:0; margin-right: 10px">
                    남은전송건수 : {{ this.pushCNT }}
                    <!-- 남은 횟수에 대한 설명보기 버튼 -->
                    <i class="uil-question-circle" @click="showGuide"></i></label>
                  <!-- 남은 횟수 가이드 -->
                  <p style="position: absolute; right:0; font-size: 0.7rem; color: #7E93A0" v-if="openCNTguide">남은 건수는
                    하루의 남은 푸시 횟수입니다.</p>
                </div>
                <!-- 최초페이지 이미지가 등록되지 않았을 때 보여줄 이미지 -->
                <div class="card-body" v-if="previewImg.length == 0">
                  <div class=""
                       style="width: calc(100% - 14px); padding-left: 10px; border: solid; border-width: thin; border-color: #a2a2a2">
                    <b-img
                        :src="`${urlEncoding(noImg)}`"
                        fluid
                        alt="Responsive image"
                        style="display: block; margin: 0px auto; "

                    ></b-img>
                  </div>
                </div>
                <!-- 이미지 미리 보기 영역 -->
                <div class="card-body" v-if="previewImg.length > 0">
                  <div class=""
                       style="width: calc(100% - 14px); border: solid; border-width: thin; border-color: #a2a2a2">
                    <b-img
                        :src="previewImg"
                        fluid
                        alt="Responsive image"
                        style="display: block; margin: 0px auto"

                    ></b-img>
                  </div>
                </div>
                <div class="col-11" style=" margin-left: 30px">
                  <input class="form-control" type="file" id="formFile" @change="previewImage"
                         accept=".jpg, .png, .gif"/>
                </div>
                <h5 class="text-muted mb-1" style="margin-top: 30px; font-weight: bold">제목(마트명)</h5>
                <!-- 푸시메시지 제목(마트명이 들어가고 수정불가능) -->
                <div class="col-md-12" style="margin-top: 12px">
                  <input
                      class="form-control"
                      type="text"
                      v-model="marketName"
                      style="background-color: #dbdbdb;"
                      readonly
                  />
                </div>
                <h5 class="text-muted mb-1" style="margin-top: 30px; font-weight: bold">내용
                  <!-- 예시문보기버튼 -->
                  <i class="uil-question-circle" @click="showEx"></i></h5>
                <!-- 내용입력란 -->
                <div class="col-md-12" style="margin-top: 12px">
                  <b-textarea
                      class="form-control"
                      type="text"
                      v-model="pushmsg"
                      id="pushcontext"
                      style="height: 200px"
                  />
                </div>
                <!-- 푸시메시지 예시부분 -->
                <div style="padding-left: 30px; margin-top: 8px" v-if="openEx">
                  <label>🚀예시 TIP</label><br>
                  <label>감사대전 특가행사! 0월00일 ~ 0월 00일</label><br>
                  <label>감사함으로 행사를 준비했습니다.</label><br>
                  <label>앞으로 더 만족시켜드리는 저희 {{ marketName }}(이)가 되겠습니다.</label><br>

                </div>
                <!-- 발송버튼 -->
                <div class="col-12" style="margin-top: 20px">
                  <b-button variant="primary" style="width: 100%" @click="submitPush">
                    푸시메시지발송
                  </b-button>
                </div>

              </div>
            </div>
          </div>
          <!-- end card -->
          <!-- 기본정보 영역 End-->
        </div>
      </div>
    </div>
  </Layout>
</template>


